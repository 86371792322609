import React, { PropsWithChildren, memo } from 'react';
import { Layout as LayoutAnt } from 'antd';
import { Wrapper } from './layout.styled';
import Header from '../app-header/header';

const { Content } = LayoutAnt;

interface ILayoutProps extends PropsWithChildren {
  className?: string;
  showStatusBar?: boolean;
}
const LayoutComponent = ({ children, className }: ILayoutProps) => {
  return (
    <Wrapper>
      <Header />
      <Content className={`app-content  ${className}`}>{children}</Content>
    </Wrapper>
  );
};

export const Layout = memo(LayoutComponent);
