import React, { memo } from 'react';
import { Layout } from '@/components/app-layout/layout.tsx';
import { Wrapper } from './homepage.styled';
import AppBg from '@/assets/bg.png';
import Coin1 from '@/assets/coin-1.png';
import Coin2 from '@/assets/coin-2.png';
import Coin3 from '@/assets/coin-3.png';
import HomeCard from '@/assets/card.png';
const HomePageComponent = () => {
  return (
    <Layout>
      <Wrapper>
        <div className='w-full relative h-full'>
          <div className='w-full h-fit relative'>
            <img src={Coin1} className='absolute left-[64%]  w-[15%] max-w-[280px] top-[6%]' alt='' />
            <img src={Coin2} className='absolute right-0  w-[15%] max-w-[280px] top-[44%] -translate-y-1/2' alt='' />
            <img src={Coin3} className='absolute left-0  w-[15%] max-w-[280px] top-[44%] -translate-y-1/2' alt='' />
            <img src={AppBg} className='w-full main-background h-full max-[600px]:max-h-[80svh]' alt='' />
          </div>

          <div className='home-content absolute left-1/2 -translate-x-1/2 flex flex-col items-center top-0 justify-between h-full  w-full pt-[5%] max-[640px]:pt-[15%]'>
            <div className='w-full flex flex-col items-center gap-5'>
              <img src={HomeCard} className='w-[80%] max-w-[480px]' alt='' />
              <div className='w-[90%]  text-center homepage-title  text-[60px] min-[900px]:leading-[76px] max-[640px]:text-[36px] max-[900px]:text-[56px]'>
                A degen twist to a classic game
              </div>
              <div
                className='play-button flex items-center justify-center text-center cursor-pointer w-[300px] h-[80px] max-[640px]:w-[240px] max-[640px]:h-[64px] text-[30px] max-[640px]:text-[24px] pb-[4px]'
                onClick={() => {
                  window.open('https://play.b2.game/', '_blank');
                }}
              >
                PLAY NOW
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </Layout>
  );
};
export const HomePage = memo(HomePageComponent);
