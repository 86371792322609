import styled from 'styled-components';
import btnBg from '@/assets/btn.png';
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  color: #003c55;
  position: absolute;
  z-index: 100;
  background: transparent;
  top: 0;
  .header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 30px;
  }
  .play-button {
    background: url(${btnBg});
    font-family: 'Nunito';
    font-size: 16px;
    font-weight: 900;
    line-height: 24px;
    text-align: center;
    background-size: 100% 100%;
    padding-bottom: 3px;
  }
  .animate-slideDown {
    animation: slideDown 1s ease-out forwards;
  }
  @keyframes slideDown {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;
