import React, { memo } from 'react';
import { Wrapper } from './header.styled';
import Logo from '@/assets/card.png';

export const HeaderComponent = () => {
  return (
    <Wrapper className='flex flex-col items-center overflow-visible'>
      <div className='header animate-slideDown py-4  app-container px-4'>
        <div className='flex justify-center '>
          <div className='logo'>
            <img className='w-[56px]' src={Logo} alt='Sprout' />
          </div>
        </div>
        <div
          className='play-button  flex items-center justify-center text-center cursor-pointer w-[160px] h-[46px] max-[640px]:w-[120px] max-[640px]:h-[40px]'
          onClick={() => {
            window.open('https://play.b2.game/', '_blank');
          }}
        >
          PLAYNOW
        </div>
      </div>
    </Wrapper>
  );
};

export const Header = memo(HeaderComponent);

export default Header;
