import { Layout } from 'antd';
import styled from 'styled-components';
import btnBg from '@/assets/btn.png';

export const Wrapper = styled(Layout)`
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  background-color: #280555;
  height: 100svh;
  overflow: hidden;
  .main-background {
    min-height: 70svh;
    object-fit: cover;
    width: 100%;
    object-position: center bottom;
  }
  .homepage-title {
    background: linear-gradient(90deg, #ffcc5a 1.71%, #fffbe1 49.97%, #ffbb54 98.23%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Nunito';
    font-weight: 800;
    text-align: center;
  }
  .play-button {
    background: url(${btnBg});
    font-family: 'Nunito';
    font-weight: 900;
    text-align: center;
    background-size: 100% 100%;
  }
  .bottom-text {
    font-family: 'Nunito';
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  }
`;
